import {Injectable} from '@angular/core';
import {action, computed, observable} from 'mobx';
import {Message, SelectItem} from 'primeng/api';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {BaseAppProperties} from '../../base-app-properties';
import {ButtonKind} from '../../directives/button.directive';
import {
  AccountsPayableInvoiceActionResponse,
  AccountsPayableInvoiceAlert,
  AccountsPayableInvoiceApproverType,
  AccountsPayableInvoiceDetails,
  AccountsPayableInvoiceDetailsApprovers,
  AccountsPayableInvoiceSummary,
  ApproveInvoiceParams,
  BudgetActualTotals,
  CreditorInvoiceEntryPosting,
  NameValuePair,
  OnHoldInvoiceParams,
  ProcessRejectedInvoiceParams,
  RejectInvoiceParams,
  StrataPlanDetails,
  WorkOrderSummary
} from '../../generated';
import {Authorities, Authority} from '../../models/authority';
import {Heading} from '../../models/heading';
import {MessageSeverity} from '../../models/message';
import {PortalType} from '../../models/portal-type';
import {AccountsPayableService} from '../../services/accounts-payable.service';
import {BudgetService} from '../../services/budget.service';
import {BaseAppStore} from '../../store/base-app.store';
import {BaseAuthenticationStore} from '../../store/base-authentication.store';
import {BaseDomainStore} from '../../store/base-domain.store';
import {ConfirmationStore} from '../../store/confirmation.store';
import {MessageStore} from '../../store/message.store';
import {getFromLocalStorage, setInLocalStorage} from '../../utils/storage.utils';
import {replaceNewlines} from '../../utils/string.utils';
import {mapAccountCodesToSelectItems, mapFundCodesToSelectItems} from '../../utils/type-mapping.utils';
import {generateUuid} from '../../utils/uuid.utils';

enum InvoiceAction {
  Approval = 'Approval',
  Rejection = 'Rejection',
  Reprocess = 'Reprocess'
}

export enum InvoiceSelector {
  ACTIVE,
  MANAGER,
  THIRTYDAYS,
  SIXTYDAYS,
  ONETWENTYDAYS,
  ALLTIME,
  ALLTIMEPLAN,
  PENDINGCOMMITTEE,
  REQUIRES2NDAUTH,
  PENDING2NDAUTH,
  SECONDAUTHOBTAINED,
  ONHOLD,
  REJECTED
}

@Injectable()
export class Store {

  private static readonly HISTORIC_EMAIL_APPROVALS_KEY = 'whittles.iris.historic_email_approvals';

  readonly invoiceSummaryTableRows = 10;

  readonly invoiceFilter: SelectItem[] = [
    { label: 'Active', value: InvoiceSelector.ACTIVE },
    { label: 'Manager approval', value: InvoiceSelector.MANAGER },
    { label: 'Completed 30 days', value: InvoiceSelector.THIRTYDAYS },
    { label: 'Completed 60 days', value: InvoiceSelector.SIXTYDAYS },
    { label: 'Completed 120 days', value: InvoiceSelector.ONETWENTYDAYS },
    { label: 'Completed all time', value: InvoiceSelector.ALLTIME },
    { label: 'Completed all time (plan)', value: InvoiceSelector.ALLTIMEPLAN },
    { label: 'Pending committee', value: InvoiceSelector.PENDINGCOMMITTEE },
    { label: 'Requires 2nd authority', value: InvoiceSelector.REQUIRES2NDAUTH },
    { label: 'Waiting 2nd authority', value: InvoiceSelector.PENDING2NDAUTH },
    { label: 'Second authority obtained', value: InvoiceSelector.SECONDAUTHOBTAINED },
    { label: 'On hold', value: InvoiceSelector.ONHOLD }
  ];

  // Triggering this subject will refresh the selected invoice details
  readonly selectedInvoiceDetails$ = new Subject();

  @observable
  private _invoiceSummaryTableFirst = 0;

  @observable
  private _selectedInvoiceFilter: SelectItem;

  @observable
  private _selectedInvoiceSummary?: AccountsPayableInvoiceSummary;

  @observable
  private _selectedPosting?: CreditorInvoiceEntryPosting;

  @observable
  private _selectedWorkOrders: WorkOrderSummary[] = [];

  @observable
  private _invoiceSummaries: AccountsPayableInvoiceSummary[] = [];

  @observable
  private _invoiceAlerts: AccountsPayableInvoiceAlert[] = [];

  @observable
  private _rejectedAlert: boolean = false;

  @observable
  private _recentlyUsedAccountCodes: SelectItem[] = [];

  @observable
  private _chartOfAccounts: SelectItem[] = [];

  @observable
  private _selectedInvoiceDetails?: AccountsPayableInvoiceDetails;

  @observable
  private _postings: CreditorInvoiceEntryPosting[] = [];

  @observable
  private _moreIndicatorText = '';

  @observable
  private _emailApprovalAddressHistory: string[] = [];

  @observable
  private _emailApprovalAddressSuggestions: string[] = [];

  @observable
  readonly postingsBudgetMap = new Map<string, BudgetActualTotals>();

  @observable
  private _filterByBudgetCodesFlag: boolean = true;

  constructor(
    private readonly appProperties: BaseAppProperties,
    private readonly accountsPayableService: AccountsPayableService,
    private readonly budgetService: BudgetService,
    private readonly messageStore: MessageStore,
    private readonly authenticationStore: BaseAuthenticationStore,
    private readonly confirmationStore: ConfirmationStore,
    private readonly domainStore: BaseDomainStore,
    private readonly appStore: BaseAppStore) {

    this.refreshEmailApprovalAddressHistoryFromLocalStorage();

    if (this.isAccountsView) {

      this.invoiceFilter = [
        ...this.invoiceFilter,
        { label: 'Rejected', value: InvoiceSelector.REJECTED }
      ];

      this._selectedInvoiceFilter = this.findInvoiceFilterItem(InvoiceSelector.REJECTED);

    } else {

      this._selectedInvoiceFilter = this.findInvoiceFilterItem(InvoiceSelector.ACTIVE);
    }
  }

  getTableRowCssClass(workOrder: WorkOrderSummary) : string {
    return '';
    // return this.appProperties.portalType === PortalType.Manager && workOrder.jobSafePending ? 'wht-table-row-warn' : '';
  }

  @computed
  get jobSafePendingMessageVisible() : boolean {

    return false; // Matt decided that is doesn't want to be seen as managing the process on the contractor's behalf

    // if (this.selectedInvoiceDetails && this.selectedInvoiceDetails.candidateWorkOrders) {
    //
    //   return this.selectedInvoiceDetails.candidateWorkOrders.filter(wo => wo.jobSafePending).length > 0;
    // } else {
    //
    //   return false;
    // }
  }

  get jobSafePendingMessage(): Message {

    return {
      severity: MessageSeverity.Warn,
      detail: 'Work orders with pending Job Safe documentation marked in yellow.'
    }
  }

  private findInvoiceFilterItem(value: InvoiceSelector): SelectItem {

    return this.invoiceFilter.find(item => item.value === value) as SelectItem;
  }

  private createPostingsBudgetMapKey(params: {
    posting: CreditorInvoiceEntryPosting;
  }): string {

    return JSON.stringify({
      strataId: this._selectedInvoiceSummary ? this._selectedInvoiceSummary.strataId : '',
      accountNumber: params.posting.accountNumber,
      fundCode: params.posting.fundCode
    });
  }

  getPostingBudgetValue(posting: CreditorInvoiceEntryPosting): BudgetActualTotals | undefined {

    if (!this.isPostingAccountNumberValid(posting.accountNumber)) {

      return undefined;
    }

    return this.postingsBudgetMap.get(this.createPostingsBudgetMapKey({
      posting
    }));
  }

  @action
  setPostingBudgetValue(params: {
    posting: CreditorInvoiceEntryPosting;
    budget: BudgetActualTotals
  }) {

    this.postingsBudgetMap.set(this.createPostingsBudgetMapKey(params), params.budget);
  }

  @action
  getBudgetAndActualTotalsForAccount(params: {
    posting: CreditorInvoiceEntryPosting;
  }) {

    if (!this._selectedInvoiceSummary || !this.isPostingAccountNumberValid(params.posting.accountNumber)) {

      return EMPTY;
    }

    const postingBudgetValue = this.postingsBudgetMap.get(this.createPostingsBudgetMapKey(params));

    if (postingBudgetValue) {

      return of(postingBudgetValue);
    }

    return this.budgetService.getBudgetAndActualTotalsForAccount({
      strataId: this._selectedInvoiceSummary.strataId,
      accountNumber: params.posting.accountNumber,
      fundCode: params.posting.fundCode
    }).pipe(
      tap(budget => this.setPostingBudgetValue({
        posting: params.posting,
        budget
      })),
    );
  }

  getChartOfAccounts(forceFetch: boolean): Observable<SelectItem[]> {

    if (!forceFetch && this.chartOfAccounts.length > 0) {

      // Chart of Accounts has already been loaded, just return it
      return of(this.chartOfAccounts);
    }

    return this.accountsPayableService.getChartOfAccounts().pipe(
      map(coa => mapAccountCodesToSelectItems(coa)),
      tap(coa => this.setChartOfAccounts(coa))
    );
  }

  getBudgetAccounts(params: {strataId: string}): Observable<SelectItem[]> {

    return this.budgetService.getBudgetAccounts(params).pipe(
      map(budgetCodes => mapAccountCodesToSelectItems(budgetCodes)),
      tap(budgetCodes => this.setChartOfAccounts(budgetCodes))
    );
  }

  getInvoiceSummaries() {

    if (this.appProperties.portalType === PortalType.Manager &&
      !this.authenticationStore.hasAnyAuthority(Authority.Accounts) &&
      !this.domainStore.selectedPortfolioId) {

      this.setInvoiceSummaries([]);

      return of([]);
    }

    return this.accountsPayableService.getInvoiceSummaries({
      portalType: this.appProperties.portalType,
      invoiceSelector: InvoiceSelector[this._selectedInvoiceFilter.value],
      portfolioId: this.fetchPortfolioSummaries() ? this.domainStore.selectedPortfolioId : undefined,
      strataId: this._selectedInvoiceFilter.value === InvoiceSelector.ALLTIMEPLAN ||
        this.appProperties.portalType === PortalType.Owner ? this.domainStore.selectedStrataId : undefined
    }).pipe(
      tap(invoiceSummaries => this.setInvoiceSummaries(invoiceSummaries)),
    );
  }

  getInvoiceAlerts() {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts) ||
      this.appProperties.portalType === PortalType.Owner ||
      !this.domainStore.selectedPortfolioId) {

      this.setInvoiceAlerts([]);

      return of(this._invoiceAlerts);
    }

    return this.accountsPayableService.getInvoiceAlerts({
      portfolioId: this.domainStore.selectedPortfolioId
    }).pipe(
      tap(invoiceAlerts => this.setInvoiceAlerts(invoiceAlerts)),
    );
  }

  @action
  getInvoiceDetails() {

    if (!this._selectedInvoiceSummary) {

      this._selectedInvoiceDetails = undefined;

      return EMPTY;
    }

    return this.accountsPayableService.getInvoiceDetails({
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary.creditorInvoiceEntryHeaderId,
      workflowProcessInstanceId: this._selectedInvoiceSummary.workflowProcessInstanceId,
      portalType: this.appProperties.portalType
    }).pipe(
      tap(invoiceDetails => this.setInvoiceDetails(invoiceDetails)));
  }

  @action
  clonePostings() {

    this._selectedPosting = undefined;
    this._postings = [];

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.postings) {

      return;
    }

    // Clone the original postings so we can work on a copy
    this._postings = JSON.parse(JSON.stringify(this.selectedInvoiceDetails.postings));

    if (this.isAllowedToActionInvoice && this.postings.length === 1) {

      this._selectedPosting = this.postings[0];
    }
  }

  @action
  setSelectedPostingsAccount(account: NameValuePair | null) {

    if (!this._selectedPosting || !account) {

      return;
    }

    this._selectedPosting.accountDescription = account.name;
    this._selectedPosting.accountNumber = account.value;
  }

  @action
  setSelectedPostingsAmount(amount: number | null) {

    if (!this._selectedPosting || amount == null || !Number.isFinite(amount)) {

      return;
    }

    this._selectedPosting.amount = {
      value: amount
    };
  }

  @action
  setPostingsFundCode(fundCode: string | null) {

    if (!this._selectedPosting || !fundCode) {

      return;
    }

    this._selectedPosting.fundCode = fundCode;
  }

  @action
  setPostingsComment(comment: string | null) {

    if (!this._selectedPosting || !comment) {

      return;
    }

    this._selectedPosting.comments = comment;
  }

  @action
  private setChartOfAccounts(chartOfAccounts: SelectItem[]) {

    this._chartOfAccounts = chartOfAccounts;
  }

  @action
  private setInvoiceSummaries(invoiceSummaries: AccountsPayableInvoiceSummary[]) {

    this._invoiceSummaries = invoiceSummaries;

    this.clearSelectedInvoice();
  }

  @action
  private setInvoiceAlerts(invoiceAlerts: AccountsPayableInvoiceAlert[]) {

    this._invoiceAlerts = invoiceAlerts;
  }

  @action
  clearSelectedInvoice(params?: {
    reselect?: boolean;
  }) {

    const reselect = !params || params.reselect == null || params.reselect;

    const canReselect = reselect && this.invoiceSummaries.length > 0;

    if (canReselect && this.invoiceSummaries[0] === this._selectedInvoiceSummary) {

      // The first invoice summary is already the selected one
      return;
    }

    this._selectedInvoiceSummary = undefined;
    this._selectedInvoiceDetails = undefined;
    this._invoiceSummaryTableFirst = 0;
    this._selectedWorkOrders = [];

    if (canReselect) {

      this._selectedInvoiceSummary = this.invoiceSummaries[0];
    }
  }

  private mapAccountCodeToSelectItem(accountCode: NameValuePair): SelectItem {

    return {
      label: `${accountCode.value} - ${accountCode.name}`,
      value: accountCode.value
    };
  }

  @action
  private setInvoiceDetails(invoiceDetails: AccountsPayableInvoiceDetails) {

    this._selectedInvoiceDetails = invoiceDetails;

    this._selectedWorkOrders = [];

    this._recentlyUsedAccountCodes = this._selectedInvoiceDetails.recentlyUsedAccountCodes.map(ac => this.mapAccountCodeToSelectItem(ac));

     this.setRejectedAlert(!!this._selectedInvoiceDetails.approvers && !!this._selectedInvoiceDetails.approvers.find(a => {
      return a.status.toLowerCase().indexOf('reject') > -1;
    }));
  }

  approveInvoice(params: {
    approvalNotes?: string;
  }) {

    const approveInvoiceParams: ApproveInvoiceParams = {
      // tslint:disable-next-line:no-non-null-assertion
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      approverType: this.getApproverType(),
      postings: this.selectedInvoiceDetails && this.selectedInvoiceDetails.postings,
      candidateWorkOrders: this._selectedWorkOrders,
      approvalNotes: params.approvalNotes
    };

    return this.accountsPayableService.approveInvoice(approveInvoiceParams).pipe(
      tap(response => {

        if (response.error === AccountsPayableInvoiceActionResponse.ErrorEnum.GstChanged) {

          this.confirmationStore.confirm({
            header: 'GST amount changed',
            message: `
            The modified postings GST amount does not match the original invoice GST amount.<br><br>
            Please modify the postings amount allocations.
            `
          });

        } else {

          this.afterSuccessfulInvoiceAction({
            response,
            invoiceAction: InvoiceAction.Approval
          });
        }
      }));
  }

  rejectInvoice(params: {
    invoiceIncorrect: boolean;
    rejectionReason?: string
  }) {

    const rejectInvoiceParams: RejectInvoiceParams = {
      // tslint:disable-next-line:no-non-null-assertion
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      approverType: this.getApproverType(),
      invoiceIncorrect: params.invoiceIncorrect,
      rejectionReason: params.rejectionReason
    };

    return this.accountsPayableService.rejectInvoice(rejectInvoiceParams).pipe(
      tap(response => {

        this.afterSuccessfulInvoiceAction({
          response,
          invoiceAction: InvoiceAction.Rejection
        });
      }));
  }

  processRejectedInvoice() {

    const processRejectedInvoiceParams: ProcessRejectedInvoiceParams = {
      // tslint:disable-next-line:no-non-null-assertion
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
    };

    return this.accountsPayableService.processRejectedInvoice(processRejectedInvoiceParams).pipe(
      tap(response => {

        if (response.error === AccountsPayableInvoiceActionResponse.ErrorEnum.ProcessRejectedNotFound) {

          this.confirmationStore.confirm({
            header: 'process rejected invoice error',
            icon: 'ui-icon-error',
            message: `
            This invoice rejection appears to already have been processed.<br><br>
            Please refresh the invoice summary list to ensure your view is up to date.
            `
          });

        } else {

          this.afterSuccessfulInvoiceAction({
            response,
            invoiceAction: InvoiceAction.Rejection
          });
        }
      }));
  }

  changePlan(params: {
    targetStrataPlan: StrataPlanDetails;
  }) {

    return this.accountsPayableService.changePlan({
      // tslint:disable-next-line:no-non-null-assertion
      sourceCreditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      targetStrataId: params.targetStrataPlan.id
    }).pipe(
      tap(() => this.afterSuccessfulChangePlan(params.targetStrataPlan)),
    );
  }

  requestEmailApproval(params: {
    emailAddress: string;
    emailBody: string;
  }) {

    this.addEmailApprovalAddressHistory(params.emailAddress);

    return this.accountsPayableService.requestEmailApproval({
      // tslint:disable-next-line:no-non-null-assertion
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      emailAddress: params.emailAddress,
      emailBody: params.emailBody
    }).pipe(
      tap(response => {

        this.afterSuccessfulEmailApprovalAction({
          response,
          message: 'email approval sent'
        });
      }),
    );
  }

  deleteEmailApproval(approver: AccountsPayableInvoiceDetailsApprovers) {

    return this.accountsPayableService.deleteEmailApproval({
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      emailAddress: approver.name,
    }).pipe(
      tap(response => {

        this.afterSuccessfulEmailApprovalAction({
          response,
          message: 'email approval deleted'
        });
      }),
    );
  }

  @action
  private afterSuccessfulEmailApprovalAction(params: {
    response: AccountsPayableInvoiceActionResponse,
    message: string;
  }) {

    // tslint:disable-next-line:no-non-null-assertion
    this._selectedInvoiceSummary!.status = params.response.status;

    this.selectedInvoiceDetails$.next();

    this.messageStore.pushMessage(params.message);
  }

  @action
  private afterSuccessfulChangePlan(targetStrataPlan: StrataPlanDetails) {

    if (targetStrataPlan.manager && targetStrataPlan.manager.value === this.domainStore.selectedPortfolioId) {

      // Plan in same portfolio so only update relevant details

      // // tslint:disable-next-line:no-non-null-assertion
      this._selectedInvoiceSummary!.strataId = targetStrataPlan.id!;

      // tslint:disable-next-line:no-non-null-assertion
      this._selectedInvoiceSummary!.planNumber = targetStrataPlan.strataNumber!;

      // tslint:disable-next-line:no-non-null-assertion
      this._selectedInvoiceSummary!.planAddress = targetStrataPlan.address!;

      // trigger fresh of details
      this.selectedInvoiceDetails$.next();

    } else {

      this.appStore.refreshCurrentRoute();
    }

    this.messageStore.pushMessage('plan change successful');
  }

  isPostingAmountModified(posting: CreditorInvoiceEntryPosting): boolean {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.postings) {

      return false;
    }

    const originaPosting = this.selectedInvoiceDetails.postings.find(p => p.id === posting.id);

    return originaPosting != null && originaPosting.amount.value !== posting.amount.value;
  }

  isPostingAmountValid(amount: number | undefined): boolean {

    return amount != null && amount > 0;
  }

  isPostingAccountNumberValid(accountNumber: string | undefined): boolean {

    return accountNumber != null && accountNumber.trim().length > 0;
  }

  @action
  updateOriginalPostingsFromClone() {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.postings) {

      return;
    }

    this.selectedInvoiceDetails.postings = this.postings;
  }

  findEmailApproverByEmail(email: string): AccountsPayableInvoiceDetailsApprovers | undefined {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.approvers) {

      return undefined;
    }

    return this.selectedInvoiceDetails.approvers.find(approver => approver.isEmailApprover === true && approver.name === email);
  }

  @action
  private afterSuccessfulInvoiceAction(params: {
    response: AccountsPayableInvoiceActionResponse;
    invoiceAction: InvoiceAction;
  }) {

    if (!this._selectedInvoiceSummary) {

      throw new Error('selectedInvoiceSummary should not be null');
    }

    // Update the new status as returned from the server
    this._selectedInvoiceSummary.status = params.response.status;

    // Remove the "On Hold" status
    this._selectedInvoiceSummary.onHold = false;

    this.autoSelectNextInvoice();

    this.messageStore.pushMessage({
      severity: MessageSeverity.Success,
      summary: `invoice ${params.invoiceAction} sent`
    });
  }

  @action
  private autoSelectNextInvoice() {

    if (!this._selectedInvoiceSummary) {

      return;
    }

    const index = this.getPreviousNextInvoiceSummaryIndex({
      next: true,
      ignoreIndexOnHold: false
    });

    if (index < this.invoiceSummaries.length) {

      // This should trigger a refresh of the next summary row
      this._selectedInvoiceSummary = this.invoiceSummaries[index];

    } else {

      // The selected invoice summary is already the last one, refetch the same index
      this.selectedInvoiceDetails$.next();
    }

    this.syncInvoiceSummaryTablePageWithSelectedIndex();
  }

  syncInvoiceSummaryTablePageWithSelectedIndex() {

    this.setInvoiceSummaryTableFirst(
      Math.floor(this.selectedInvoiceSummaryIndex / this.invoiceSummaryTableRows) * this.invoiceSummaryTableRows);
  }

  private getApproverType(): AccountsPayableInvoiceApproverType {

    switch (this.appProperties.portalType) {

      case PortalType.Manager:

        return AccountsPayableInvoiceApproverType.MANAGER;

      case PortalType.Owner:

        return AccountsPayableInvoiceApproverType.COMMITTEE;
    }

    throw new Error(`Unknown portaType: ${this.appProperties.portalType}`);
  }

  @action
  removeSelectedPosting() {

    if (this.selectedPostingIndex === -1) {

      return;
    }

    this.postings.splice(this.selectedPostingIndex, 1).slice();

    // Need to assign a new reference to postings to get p-dataTable to update
    this._postings = this.postings.slice();

    this._selectedPosting = undefined;
  }

  @action
  addPosting() {

    this.postings.push({
      id: generateUuid(),
      amount: {
        value: 0
      },
      fundCode: 'A',
      accountNumber: '',
      accountDescription: '',
      comments: ''
    });

    // Need to assign a new reference to postings to get p-dataTable to update
    this._postings = this.postings.slice();

    this._selectedPosting = this.postings[this.postings.length - 1];
  }

  private isPostingValid(posting: CreditorInvoiceEntryPosting): boolean {

    return posting.amount.value > 0 && posting.accountNumber.trim().length > 0;
  }

  updateInvoiceOnHold(params: {
    onHold: boolean;
    onHoldReason?: string
  }) {

    const onHoldInvoiceParams = {
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      onHold: params.onHold,
      onHoldReason: params.onHoldReason
    };

    return this.accountsPayableService.updateInvoiceOnHold(onHoldInvoiceParams).pipe(
      tap(() => this.afterSuccessfulUpdateInvoiceOnHold(onHoldInvoiceParams)),
    );
  }

  overrideCommitteeApproval(overrideReason: string) {

    return this.accountsPayableService.overrideCommitteeApproval({
      // tslint:disable-next-line:no-non-null-assertion
      workflowProcessInstanceId: this._selectedInvoiceSummary!.workflowProcessInstanceId,
      overrideReason
    }).pipe(
      tap(response => {

        this.afterSuccessfulInvoiceAction({
          response,
          invoiceAction: InvoiceAction.Approval
        });
      }));
  }

  reprocessRejectedInvoice(args: {
    reason: string
  }) {

    return this.accountsPayableService.reprocessRejectedInvoice({
      // tslint:disable-next-line:no-non-null-assertion
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      reason: args.reason
    }).pipe(
      tap(response => {

        this.afterSuccessfulInvoiceAction({
          response,
          invoiceAction: InvoiceAction.Reprocess
        });
      })
    );
  }

  @action
  setMoreIndicatorText(moreIndicatorText: string | undefined) {

    if (!moreIndicatorText) {

      this._moreIndicatorText = '';

    } else {

      this._moreIndicatorText = moreIndicatorText;
    }
  }

  @action
  emailApprovalAddressComplete(emailAddress: string) {

    this._emailApprovalAddressSuggestions = this.emailApprovalAddressHistory.filter(
      emailHistory => emailHistory.includes(emailAddress.toLowerCase()));
  }

  @action
  addEmailApprovalAddressHistory(emailAddress: string) {

    const normalisedEmailAddress = emailAddress.toLowerCase();

    const index = this.emailApprovalAddressHistory.indexOf(normalisedEmailAddress);

    if (index > -1) {

      this.emailApprovalAddressHistory.splice(index, 1);
    }

    this._emailApprovalAddressHistory = [
      normalisedEmailAddress,
      ...this.emailApprovalAddressHistory
    ].slice(0, 50);

    setInLocalStorage(Store.HISTORIC_EMAIL_APPROVALS_KEY, this.emailApprovalAddressHistory);
  }

  @action
  private refreshEmailApprovalAddressHistoryFromLocalStorage() {

    const historicEmailApprovals = getFromLocalStorage<string[]>(Store.HISTORIC_EMAIL_APPROVALS_KEY);

    if (historicEmailApprovals) {

      this._emailApprovalAddressHistory = historicEmailApprovals;
    }
  }

  @action
  private afterSuccessfulUpdateInvoiceOnHold(params: OnHoldInvoiceParams) {

    // tslint:disable-next-line:no-non-null-assertion
    this._selectedInvoiceSummary!.onHold = params.onHold;

    // tslint:disable-next-line:no-non-null-assertion
    this.selectedInvoiceDetails!.onHoldReason = params.onHoldReason;

    this.messageStore.pushMessage({
      severity: MessageSeverity.Success,
      summary: `successfully ${params.onHold ? 'put' : 'removed'} on hold`
    });
  }

  @action
  setFundCode(fundCode: string, posting: CreditorInvoiceEntryPosting) {

    posting.fundCode = fundCode;
  }

  @action
  setAccountNumber(accountNumber: string, posting: CreditorInvoiceEntryPosting) {

    posting.accountNumber = accountNumber;

    // tslint:disable-next-line:no-non-null-assertion
    const accountCode = this.selectedInvoiceDetails!.recentlyUsedAccountCodes.find(ac => ac.value === accountNumber);

    // tslint:disable-next-line:no-non-null-assertion
    posting.accountDescription = accountCode!.name;
  }

  @action
  onSelectPreviousInvoice() {

    const index = this.getPreviousNextInvoiceSummaryIndex({
      next: false,
      ignoreIndexOnHold: true
    });

    this._selectedInvoiceSummary = this.invoiceSummaries[index];

    this.syncInvoiceSummaryTablePageWithSelectedIndex();
  }

  @action
  onSelectNextInvoice() {

    const index = this.getPreviousNextInvoiceSummaryIndex({
      next: true,
      ignoreIndexOnHold: true
    });

    this._selectedInvoiceSummary = this.invoiceSummaries[index];

    this.syncInvoiceSummaryTablePageWithSelectedIndex();
  }

  isEmailApproverLinkEnabled(approver: AccountsPayableInvoiceDetailsApprovers): boolean {

    return this.appProperties.portalType === PortalType.Manager && !!approver.isEmailApprover;
  }

  isEmailApproverDeleteEnabled(approver: AccountsPayableInvoiceDetailsApprovers): boolean {

    return this.appProperties.portalType === PortalType.Manager &&
      this.isAllowedToActionInvoice &&
      !!approver.isEmailApprover &&
      approver.date == null;
  }

  @action
  setSecondAuthority(params: {
    secondAuthorityFlag: boolean;
  }) {

    return this.accountsPayableService.setSecondAuthority({
      // tslint:disable-next-line:no-non-null-assertion
      creditorInvoiceEntryHeaderId: this._selectedInvoiceSummary!.creditorInvoiceEntryHeaderId,
      secondAuthorityFlag: params.secondAuthorityFlag
    }).pipe(
      tap(() => this.afterSuccessfulSetSecondAuthority(params)),
    );
  }

  @action
  private afterSuccessfulSetSecondAuthority(params: {
    secondAuthorityFlag: boolean;
  }) {

    // tslint:disable-next-line:no-non-null-assertion
    this._selectedInvoiceSummary!.secondAuthorityRequired = params.secondAuthorityFlag ?
      AccountsPayableInvoiceSummary.SecondAuthorityRequiredEnum.OBTAINED :
      AccountsPayableInvoiceSummary.SecondAuthorityRequiredEnum.REQUIRED;

    this.messageStore.pushMessage('second authority updated');
  }

  private getPreviousNextInvoiceSummaryIndex(args: {
    next: boolean;
    ignoreIndexOnHold: boolean;
  }): number {

    let index: number;
    for (
      index = args.next ? this.selectedInvoiceSummaryIndex + 1 : this.selectedInvoiceSummaryIndex - 1;
      args.next ? index < this.invoiceSummaries.length : index > this.invoiceSummaries.length;
      args.next ? index++ : index--) {

      const previousNextInvoiceSummary = this.invoiceSummaries[index];

      const previousNextInvoiceMatchesCompanyFilter =
        !this.domainStore.selectedInvoiceApprovalCompany ||
        !this.domainStore.selectedInvoiceApprovalCompany.id ||
        this.invoiceSummaryMatchesCompanyFilter(previousNextInvoiceSummary);

      if (previousNextInvoiceMatchesCompanyFilter && (args.ignoreIndexOnHold || !previousNextInvoiceSummary.onHold)) {

        break;
      }
    }

    return index;
  }

  @action
  clearBranchFilter() {

    this.domainStore.setSelectedInvoiceApprovalCompany(this.domainStore.branchesForUserOptional[0]);
  }

  @computed
  get selectedInvoiceSummaryIndex(): number {

    return this.invoiceSummaries.findIndex(invoiceSummary => this._selectedInvoiceSummary != null &&
      this._selectedInvoiceSummary.workflowProcessInstanceId === invoiceSummary.workflowProcessInstanceId);
  }

  @action
  setSelectedInvoiceSummary(value: AccountsPayableInvoiceSummary | undefined) {

    this._selectedInvoiceSummary = value;
  }

  @computed
  get secondAuthorityApprovedAlerts() {

    return this._invoiceAlerts.filter(a => a.type === AccountsPayableInvoiceAlert.TypeEnum.SECONDAUTHORITYAPPROVED);
  }

  @computed
  get hasSecondAuthorityApprovedAlerts() {

    return this.secondAuthorityApprovedAlerts.length > 0;
  }

  @computed
  get rejectedAlert() {

    return this.appProperties.portalType === PortalType.Manager &&
      !this.authenticationStore.hasAnyAuthority(Authority.Accounts) &&
      this._rejectedAlert ;
  }

  @action
  setRejectedAlert(val: boolean) {

    this._rejectedAlert = val;
  }




  @computed
  get secondAuthorityApprovedAlertMessage(): Message {

    const invoiceNumbers = this.secondAuthorityApprovedAlerts
      .map(a => a.description)
      .join(', ');

    return {
      severity: 'error',
      detail: `The following invoices have been approved but still require a second authority. Invoices # : ${invoiceNumbers}`
    };
  }

  get rejectedAlertMessage(): Message {

    return {
      severity: MessageSeverity.Warn,
      detail: 'This invoice has been rejected by at least 1 approver.'
    }
  }

  get isManager() {
    return this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @computed
  get isAllowedToActionInvoice(): boolean {

    return this.selectedInvoiceDetails != null && this.selectedInvoiceDetails.isActionable === true;
  }

  @computed
  get isAllowedToRejectInvoice(): boolean {

    return (this.getApproverType() !== AccountsPayableInvoiceApproverType.MANAGER) ||
      this.authenticationStore.hasAnyAuthority(Authority.RejectWhittlesInvoice) ||
      (this.selectedInvoiceDetails != null && this.selectedInvoiceDetails.isRejectable === true);
  }

  get isAllowedToChangeAccountOrPlan(): boolean {

    return this.selectedInvoiceDetails != null && this.selectedInvoiceDetails.isEditable;
  }

  @computed
  get isAllowedToApproveInvoice(): boolean {

    return this.isAllowedToActionInvoice && (!this.isSecondAuthorityRequired || this.secondAuthorityFlag);
  }

  @computed
  get isAllowedToOverrideInvoice(): boolean {

    // isOverridable signifies waiting on committee approval.
    return this.selectedInvoiceDetails != null && this.selectedInvoiceDetails.isOverridable === true;
  }

  @computed
  get isAllowedToProcessRejected(): boolean {

    return this.isAllowedToActionInvoice && this._selectedInvoiceFilter.value === InvoiceSelector.REJECTED;
  }

  @computed
  get isAllowedToReprocessRejected(): boolean {

    return this.isAllowedToProcessRejected;
  }

  @computed
  get isSelectedInvoiceOnHold(): boolean {

    if (!this._selectedInvoiceSummary || !this._selectedInvoiceSummary.onHold) {

      return false;
    }

    return this._selectedInvoiceSummary.onHold;
  }

  @computed
  get selectedInvoiceOnHoldButtonKind(): ButtonKind {

    return this.isSelectedInvoiceOnHold ? ButtonKind.RemoveOnHold : ButtonKind.PutOnHold;
  }

  @action
  setSelectedInvoiceFilter(value: SelectItem) {

    this._selectedInvoiceFilter = value;
  }

  @action
  setInvoiceSummaryTableFirst(value: number) {

    this._invoiceSummaryTableFirst = value;
  }

  @action
  setSelectedPosting(value: CreditorInvoiceEntryPosting | undefined) {

    this._selectedPosting = value;
  }

  get selectedPostingAccount(): NameValuePair | null {

    if (!this._selectedPosting) {

      return null;
    }

    const selectedPosting = this._selectedPosting;

    const selectedPostingAccount = this.chartOfAccounts.find(coa => coa.value.value === selectedPosting.accountNumber);

    return selectedPostingAccount ? selectedPostingAccount.value : null;
  }

  @computed
  get selectedPostingIndex(): number {

    if (!this._selectedPosting) {

      return -1;
    }

    const selectedPosting = this._selectedPosting;

    return this.postings.findIndex(posting => posting.id === selectedPosting.id);
  }

  @computed
  get originalTotalInvoicePostingsAmount(): number {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.postings) {

      return 0;
    }

    const total = this.selectedInvoiceDetails.postings.reduce((prev, curr) => {

      return prev + curr.amount.value;

    }, 0);

    return total;
  }

  @computed
  get runningTotalInvoicePostingsAmount(): number {

    const total = this.postings.reduce((prev, curr) => {

      return prev + curr.amount.value;

    }, 0);

    return total;
  }

  @computed
  get isOriginalAndRunningTotalInvoicePostingsAmountEqual() {

    return this.originalTotalInvoicePostingsAmount === this.runningTotalInvoicePostingsAmount;
  }

  @computed
  get isAllPostingsValid() {

    return this.postings.every(posting => this.isPostingValid(posting));
  }

  @computed
  get isAllowedToOKPosting() {

    return this.isAllowedToActionInvoice &&
      this.isAllPostingsValid &&
      this.isOriginalAndRunningTotalInvoicePostingsAmountEqual;
  }

  @computed
  get originalSelectedPosting(): CreditorInvoiceEntryPosting | undefined {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.postings || !this.selectedPosting) {

      return undefined;
    }

    const selectedPosting = this.selectedPosting;

    return this.selectedInvoiceDetails.postings.find(posting => posting.id === selectedPosting.id);
  }

  @computed
  get isAllowedToAddPosting() {

    return this.isAllowedToActionInvoice;
  }

  @computed
  get isAllowedToDeletePosting() {

    return this.isAllowedToActionInvoice && this._selectedPosting != null && this.postings.length > 1;
  }

  @computed
  get isAllowedToInlineEditPosting() {

    return this.isAllowedToActionInvoice &&
      this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @computed
  get isInvoiceFilterVisible() {

    return this.appProperties.portalType === PortalType.Manager;
  }

  @computed
  get isAllowedToSelectWorkOrder() {

    if (this.appProperties.portalType === PortalType.Owner) {
      return false;
    }

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;
    }

    return this.isAllowedToActionInvoice && this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @action
  setSelectedWorkOrders(val: WorkOrderSummary[]) {

    this._selectedWorkOrders = val;
  }

  @computed
  get isSecondAuthorityRequired(): boolean {

    if (this.appProperties.portalType === PortalType.Owner || !this._selectedInvoiceSummary) {

      return false;
    }

    return this._selectedInvoiceSummary.secondAuthorityRequired !== AccountsPayableInvoiceSummary.SecondAuthorityRequiredEnum.NOTREQUIRED;
  }

  @computed
  get isSecondAuthorityPending(): boolean {

    return this._selectedInvoiceSummary != null &&
      this._selectedInvoiceSummary.secondAuthorityRequired === AccountsPayableInvoiceSummary.SecondAuthorityRequiredEnum.REQUIRED;
  }

  @computed
  get secondAuthorityMessage(): Message {

    return {
      severity: this.isSecondAuthorityPending ? 'warn' : 'success',
      detail: this.isSecondAuthorityPending ?
        'A second authority is required prior to invoice approval.' : 'A second authority has been processed.'
    };
  }

  @computed
  get isOnHoldReasonVisible(): boolean {

    if (!this.selectedInvoiceDetails) {

      return false;
    }

    return this.appProperties.portalType === PortalType.Manager && !!this.selectedInvoiceDetails.onHoldReason;
  }

  @computed
  get onHoldReason(): Message {

    if (!this._selectedInvoiceSummary || !this.selectedInvoiceDetails || !this.selectedInvoiceDetails.onHoldReason) {

      return {};
    }

    return {
      severity: this._selectedInvoiceSummary.onHold ? MessageSeverity.Error : MessageSeverity.Warn,
      detail: `<b>${this._selectedInvoiceSummary.onHold ? 'Currently' : 'Previously'} on Hold</b><br><br>
      ${replaceNewlines(this.selectedInvoiceDetails.onHoldReason)}
      `
    };
  }

  @computed
  get candidateWorkOrders(): boolean {

    if (this.selectedInvoiceDetails &&
      this.selectedInvoiceDetails.candidateWorkOrders &&
      this.selectedInvoiceDetails.candidateWorkOrders.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  @computed
  get isLinkedWorkOrderVisible(): boolean {

    if (!this.candidateWorkOrders ||
      !this.selectedInvoiceDetails ||
      !this.selectedInvoiceDetails.linkedWorkOrderId) {
      return false;
    }

    return this.appProperties.portalType === PortalType.Manager;
  }

  @computed
  get linkedWorkOrderReason(): Message {

    if (!this._selectedInvoiceSummary ||
      !this.selectedInvoiceDetails ||
      !this.selectedInvoiceDetails.candidateWorkOrders ||
      !this.selectedInvoiceDetails.linkedWorkOrderId ||
      !this.linkedWorkOrder) {

      return {};
    }

    if (this.isAllowedToActionInvoice) {
      return {
        severity: this.linkedWorkOrderSelected ? MessageSeverity.Success : MessageSeverity.Warn,
        detail: `Work Order # ${this.linkedWorkOrder.orderNumber} relates to this invoice and has ${!this.linkedWorkOrderSelected ? 'not '
          : ''}been selected for closure.`
      };
    } else {
      return {
        severity: 'info',
        detail: `Work Order # ${this.linkedWorkOrder.orderNumber} relates to this invoice.`
      };
    }
  }

  @computed
  get linkedWorkOrder(): WorkOrderSummary | undefined {
    if (!this.selectedInvoiceDetails ||
      !this.selectedInvoiceDetails.candidateWorkOrders ||
      !this.selectedInvoiceDetails.linkedWorkOrderId) {
      return undefined;
    }

    return this.selectedInvoiceDetails.candidateWorkOrders.find(
      w => this.selectedInvoiceDetails ? w.id === this.selectedInvoiceDetails.linkedWorkOrderId : false);
  }

  @computed
  get linkedWorkOrderSelected(): boolean {

    if (!this.selectedInvoiceDetails ||
      !this.selectedInvoiceDetails.candidateWorkOrders ||
      !this.selectedInvoiceDetails.linkedWorkOrderId) {

      return false;
    }

    return this._selectedWorkOrders.some(sw => this.linkedWorkOrder !== undefined && sw.id === this.linkedWorkOrder.id);
  }

  @computed
  get linkedWorkorderNotSelected() {

    return this.linkedWorkOrder != null && !this.linkedWorkOrderSelected;
  }

  @computed
  get isApproveButtonVisible(): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;
    }

    if (this.isSelectedInvoiceOnHold){
      return false;
    }

    return this.appProperties.portalType === PortalType.Owner ||
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @computed
  get isRejectButtonVisible(): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;
    }

    return this.appProperties.portalType === PortalType.Owner ||
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @computed
  get isOnHoldButtonVisible(): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;
    }

    return this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @computed
  get isOverrideButtonVisible(): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;
    }

    return this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  @computed
  get isAccountCodesButtonVisible(): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;

    } else if (this.appProperties.portalType === PortalType.Manager) {

      return this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);

    } else if (this.appProperties.portalType === PortalType.Owner) {

      return this.authenticationStore.hasAnyAuthority(Authority.ChangeAccountCode);
    }

    return false;
  }

  @computed
  get isProcessRejectedButtonVisible(): boolean {

    return this.isAccountsView;
  }

  @computed
  get isReprocessRejectedButtonVisible(): boolean {

    return this.isAccountsView;
  }

  @computed
  get isEmailApprovalButtonVisible(): boolean {

    if (this.authenticationStore.hasAnyAuthority(Authority.Accounts)) {

      return false;
    }

    if (this.isSelectedInvoiceOnHold){
      return false;
    }

    return this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement);
  }

  private fetchPortfolioSummaries(): boolean {

    const strataManager: boolean = this.appProperties.portalType === PortalType.Manager &&
      !this.authenticationStore.hasAnyAuthority(Authority.Accounts);

    const accountsUsingPortfolioView: boolean = this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(Authority.Accounts) &&
      this._selectedInvoiceFilter.value !== InvoiceSelector.REJECTED;

    return strataManager || accountsUsingPortfolioView;
  }

  @computed
  get isBranchDropdownVisible(): boolean {

    return this.isAccountsView;
  }

  @computed
  get isBranchDropdownEnabled(): boolean {

    return this._selectedInvoiceFilter.value === InvoiceSelector.REJECTED;
  }

  @computed
  get isAccountsView(): boolean {

    return this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(Authority.Accounts);
  }

  @computed
  get invoiceSummaries() {

    const selectedInvoiceApprovalCompany = this.domainStore.selectedInvoiceApprovalCompany;

    if (!selectedInvoiceApprovalCompany || !selectedInvoiceApprovalCompany.id) {

      return this._invoiceSummaries;
    }

    return this._invoiceSummaries.filter(invoiceSummary => this.invoiceSummaryMatchesCompanyFilter(invoiceSummary));
  }

  private invoiceSummaryMatchesCompanyFilter(invoiceSummary: AccountsPayableInvoiceSummary): boolean {

    const selectedInvoiceApprovalCompany = this.domainStore.selectedInvoiceApprovalCompany;

    if (!selectedInvoiceApprovalCompany) {

      return false;
    }

    const companyMatches = invoiceSummary.company.id === selectedInvoiceApprovalCompany.id;

    const branchMatches = invoiceSummary.company.branch === selectedInvoiceApprovalCompany.branch;

    // If a branch specified in company filter, filter on branch field as well.
    return this.selectedInvoiceApprovalCompanyIsBranch ? companyMatches && branchMatches : companyMatches;
  }

  @computed
  get selectedInvoiceApprovalCompanyIsBranch() {

    const selectedInvoiceApprovalCompany = this.domainStore.selectedInvoiceApprovalCompany;

    if (!selectedInvoiceApprovalCompany || !selectedInvoiceApprovalCompany.branch) {

      return false;
    }

    return selectedInvoiceApprovalCompany.branch !== selectedInvoiceApprovalCompany.name;
  }

  @computed
  get pageTitle() {

    return this.authenticationStore.hasAnyAuthority(Authority.Accounts) ? 'accounts invoice rejection' : Heading.InvoiceApproval;
  }

  @computed({ keepAlive: true })
  get fundCodes(): SelectItem[] {

    if (!this._selectedInvoiceDetails) {

      return [];
    }

    return mapFundCodesToSelectItems(this._selectedInvoiceDetails.funds);
  }

  private mapPostingToSelectItem(posting: CreditorInvoiceEntryPosting): SelectItem {

    return {
      label: `${posting.accountNumber} - ${posting.accountDescription}`,
      value: posting.accountNumber
    };
  }

  @action
  getRecentlyUsedAccountCodes(posting: CreditorInvoiceEntryPosting): SelectItem[] {

    if (!this.selectedInvoiceDetails) {

      return [];
    }

    const postingAccountCode = this._recentlyUsedAccountCodes.find(ac => ac.value === posting.accountNumber);

    if (!postingAccountCode) {

      this._recentlyUsedAccountCodes.unshift(this.mapPostingToSelectItem(posting));
    }

    return this._recentlyUsedAccountCodes;
  }

  @computed
  get isPreviosInvoiceSelectionEnabled() {

    if (this.selectedInvoiceSummaryIndex === -1 || this.selectedInvoiceSummaryIndex === 0) {

      return false;
    }

    return true;
  }

  @computed
  get isNextInvoiceSelectionEnabled() {

    if (this.selectedInvoiceSummaryIndex === -1 || this.selectedInvoiceSummaryIndex === this.invoiceSummaries.length - 1) {

      return false;
    }

    return true;
  }

  @computed
  get isChangePlanButtonVisible() {

    return this.appProperties.portalType === PortalType.Manager &&
      this.authenticationStore.hasAnyAuthority(...Authorities.PortfolioManagement) &&
      this.isAllowedToActionInvoice;
  }

  @computed
  get isEmailApprovalFormReadonly(): null | 'readonly' {

    return this.isAllowedToActionInvoice ? null : 'readonly';
  }

  @computed
  get isAnyEmailApprovers() {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.approvers) {

      return false;
    }

    return this.selectedInvoiceDetails.approvers.some(approver => !!approver.isEmailApprover);
  }

  @computed
  get isEmailApprovalOustanding() {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.approvers) {

      return false;
    }

    return this.selectedInvoiceDetails.approvers.some(approver => !!approver.isEmailApprover && !approver.date);
  }

  @computed
  get isAtLeast1EmailApprovalComplete() {

    if (!this.selectedInvoiceDetails || !this.selectedInvoiceDetails.approvers) {

      return false;
    }

    return this.selectedInvoiceDetails.approvers.some(approver => !!approver.isEmailApprover && !!approver.date);
  }

  @computed
  get isApprovalNotesRequired() {

    return this.appProperties.portalType === PortalType.Manager && (this.isSecondAuthorityPending || this.isEmailApprovalOustanding);
  }

  @computed
  get isActionInvoiceWarningsVisible() {

    return this.appProperties.portalType === PortalType.Manager &&
      (this.linkedWorkorderNotSelected || this.isSecondAuthorityPending || this.isEmailApprovalOustanding);
  }

  @computed
  get secondAuthorityFlag() {

    return this._selectedInvoiceSummary != null &&
      this._selectedInvoiceSummary.secondAuthorityRequired === AccountsPayableInvoiceSummary.SecondAuthorityRequiredEnum.OBTAINED;
  }

  @action
  setFilterByBudgetCodesFlag(val: boolean) {
    this._filterByBudgetCodesFlag = val;
  }

  get filterByBudgetCodesFlag() {
    return this._filterByBudgetCodesFlag;
  }

  @computed
  get isSecondAuthorityCheckboxEnabled() {

    return this.isAtLeast1EmailApprovalComplete && !this.isEmailApprovalOustanding;
  }

  @computed
  get secondAuthorityCheckboxLabel() {

    if (!this.isSecondAuthorityRequired) {

      return '';

    } else if (!this.isAnyEmailApprovers) {

      return 'Hint: obtain 2nd authority using the email approval button';

    } else if (!this.isSecondAuthorityCheckboxEnabled) {

      return 'Hint: waiting for email approval';

    } else if (!this.secondAuthorityFlag) {

      return 'Hint: tick 2nd authority';
    }

    return '';
  }

  @computed
  get gstRegistered() {

    if (!this.selectedInvoiceSummary) {

      return '';
    }

    return this.selectedInvoiceSummary.gstRegistered ? 'Yes' : 'No';
  }

  get chartOfAccounts() {

    return this._chartOfAccounts;
  }

  get selectedInvoiceDetails() {

    return this._selectedInvoiceDetails;
  }

  get postings() {

    return this._postings;
  }

  get moreIndicatorText() {

    return this._moreIndicatorText;
  }

  get emailApprovalAddressHistory() {

    return this._emailApprovalAddressHistory;
  }

  get emailApprovalAddressSuggestions() {

    return this._emailApprovalAddressSuggestions;
  }

  get selectedInvoiceSummary() {

    return this._selectedInvoiceSummary;
  }

  get selectedInvoiceFilter() {

    return this._selectedInvoiceFilter;
  }

  get invoiceSummaryTableFirst() {

    return this._invoiceSummaryTableFirst;
  }

  get selectedPosting() {

    return this._selectedPosting;
  }

  get selectedWorkOrders() {

    return this._selectedWorkOrders;
  }
}
